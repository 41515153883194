<template>
	<div class="container intlcomfirm-box" style="margin-top:20px">
		<el-row type="flex" justify="space-between">
			<el-col :span="16" style="margin-right:20px;">
				<div>
					<el-row type="flex" justify="space-between" class="fill-title">
						<div>
							<span>乘机人信息</span>
							<span class="txt">所有信息请与证件一致</span>
						</div>
						<div>
							<span class="login-btns">登录</span><span class="gray6 ft12 mr10">可选择常用乘机人</span>
							<el-button type="danger" class="select-passenger-btn" @click="openPerson(1)">选择常旅客</el-button>
						</div>
					</el-row>
					<div class="fill-box">
						<div class="opportunity-box">
							<el-row type="flex" :gutter="24" class="opportunity-list">
								<el-col :span="4">
									<span class="num">1</span>
								</el-col>
								<el-col :span="16">
									<el-row type="flex" :gutter="24" align="middle" class="mb10">
										<el-col :span="4" style="text-align: right;">
											<span>姓名</span>
										</el-col>
										<el-col :span="18">
											<el-input placeholder="姓名,如:张三">

											</el-input>
										</el-col>
									</el-row>
									<el-row type="flex" :gutter="24" align="middle" class="mb10">
										<el-col :span="4" style="text-align: right;">
											<span>证件类型</span>
										</el-col>
										<el-col :span="18">
											<el-select v-model="value" placeholder="请选择" style="width:100%">
												<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
												</el-option>
											</el-select>
										</el-col>
									</el-row>
									<el-row type="flex" :gutter="24" align="middle" class="mb10">
										<el-col :span="4" style="text-align: right;">
											<span>证件号码</span>
										</el-col>
										<el-col :span="18">
											<el-input placeholder="证件号码">

											</el-input>
										</el-col>
									</el-row>
									<el-row type="flex" :gutter="24" align="middle" class="mb10">
										<el-col :span="4" style="text-align: right;">
											<span>性别</span>
										</el-col>
										<el-col :span="18">

											<el-radio v-model="radio" label="1">男</el-radio>
											<el-radio v-model="radio" label="2">女</el-radio>
										</el-col>


									</el-row>

								</el-col>
								<el-col :span="4">
									<span class="del">删除</span>
								</el-col>
							</el-row>
							<el-row type="flex" :gutter="24" class="opportunity-list">
								<el-col :span="4">
									<span class="num">1</span>
								</el-col>
								<el-col :span="16">
									<el-row type="flex" :gutter="24" align="middle" class="mb10">
										<el-col :span="4" style="text-align: right;">
											<span>姓名</span>
										</el-col>
										<el-col :span="18">
											<el-input placeholder="姓名,如:张三">

											</el-input>
										</el-col>
									</el-row>
									<el-row type="flex" :gutter="24" align="middle" class="mb10">
										<el-col :span="4" style="text-align: right;">
											<span>证件类型</span>
										</el-col>
										<el-col :span="18">
											<el-select v-model="value" placeholder="请选择" style="width:100%">
												<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
												</el-option>
											</el-select>
										</el-col>
									</el-row>
									<el-row type="flex" :gutter="24" align="middle" class="mb10">
										<el-col :span="4" style="text-align: right;">
											<span>证件号码</span>
										</el-col>
										<el-col :span="18">
											<el-input placeholder="证件号码">

											</el-input>
										</el-col>
									</el-row>
									<el-row type="flex" :gutter="24" align="middle" class="mb10">
										<el-col :span="4" style="text-align: right;">
											<span>性别</span>
										</el-col>
										<el-col :span="18">

											<el-radio v-model="radio" label="1">男</el-radio>
											<el-radio v-model="radio" label="2">女</el-radio>
										</el-col>


									</el-row>
								</el-col>
								<el-col :span="4">
									<span class="del">删除</span>
								</el-col>
							</el-row>
							<div class="pd10">
								<el-button type="danger" class="select-passenger-btn">新增乘机人</el-button>
							</div>
						</div>
					</div>
				</div>
				<div class="fill-in-box">
					<div>
						<el-row type="flex" justify="space-between" class="fill-title">
							<el-row type="flex" align="middle">
								<span class="iconfont icon-baoxian" style="font-size:20px;margin-right:10px;"></span>
								<span class="mr10">航空意外险</span>
								<span class="gray9 ft12">出行有保障，安心一起飞</span>
							</el-row>
						</el-row>
					</div>
					<div class="fill-box" style="border-bottom:1px solid #ececec;">
						<el-row type="flex" justify="space-between" align="middle" class="air-item" style="width:100%;">
							<el-row type="flex" justify="space-between" style="width:100%;" align="top">
								<div>
									<el-row type="flex">
										<span class="iconfont icon-checkbox"></span>
										<span class="iconfont icon-duoxuanxuanzhong icon-xian"></span>
										<el-row>
											<div class="ft18 ml10 mb5">美亚"乐悠游"境外旅行保障计划</div>
											<div class="ft14">
												<span class="gray9 ml10">出填必备，全方位保障 </span>
												<span class="view-detail">查看详情</span>
											</div>
										</el-row>
									</el-row>

								</div>
								<div class="ml40 ft12 mr20">
									<div class="red">￥20/份/段</div>
								</div>
							</el-row>

						</el-row>

					</div>
				</div>

				<div class="fill-in-box">
					<div>
						<el-row type="flex" justify="space-between" class="fill-title">
							<div>
								<span class="iconfont icon-zhifuanquan" style="font-size:20px;margin-right:10px;"></span>
								<span>现金劵</span>
							</div>
						</el-row>
					</div>
					<div class="fill-box">
						<div class="ft14 gray6 mt10">可用现金劵（２）</div>
						<el-row :gutter="24">
							<el-col :span="11" class="pd10 mr20" v-for="(item,index) in [1,2,3,4]" :key="index">
								<el-row type="flex" :gutter="24" align="middle" class="cash-list cash-list-active">
									<el-col :span="5">
										<el-row type="flex" align="middle" justify="center" class="cash-left">
											<div class="txt2">
												<em class="ft16">￥</em>100
											</div>
										</el-row>

									</el-col>
									<el-col :span="18">
										<div class="info">
											<p class="ft16">通用现金券</p>
											<p class="gray6">有效期：2015.02.02至2015.02.02</p>
											<p class="gray6">使用规则：单次使用,不可叠加 </p>
										</div>
										<span class="iconfont icon-dibugouxuan gray9"></span>
									</el-col>
								</el-row>

							</el-col>
						</el-row>
					</div>
				</div>
				<div class="fill-in-box">
					<div>
						<el-row type="flex" justify="space-between" class="fill-title">
							<div>
								<span class="iconfont icon-yonghuming1" style="font-size:20px;margin-right:10px;"></span>
								<span>联系信息</span>
							</div>
						</el-row>
					</div>
					<div class="fill-box">
						<el-row :gutter="24" type="flex" justify="center">
							<div style="width:70%;margin-top:20px;">
								<el-row type="flex" :gutter="24" align="middle" class="mb10">
									<el-col :span="4" style="text-align: right;">
										
										<span>联系人</span>
									</el-col>
									<el-col :span="18">
										<el-input placeholder="请填写联系人姓名">
										</el-input>
									</el-col>
								</el-row>
								<el-row type="flex" :gutter="24" align="middle" class="mb10">
									<el-col :span="4" style="text-align: right;">
										<span>手机号码</span>
									</el-col>
									<el-col :span="18">
										<el-input placeholder="请填写手机号码">

										</el-input>
									</el-col>
								</el-row>
								<el-row type="flex" :gutter="24" align="middle" class="mb10">
									<el-col :span="4" style="text-align: right;">
										<span></span>
									</el-col>
									<el-col :span="18">
										<el-row type="flex">
											<el-input placeholder="请输入验证码">

											</el-input>
											<el-button type="danger" v-show="show2" @click="getCode">获取验证码</el-button>
											<el-button v-show="!show2" class="count" type="danger">{{count}} s</el-button>

										</el-row>

									</el-col>
								</el-row>
								<el-row type="flex" :gutter="24" align="middle" class="mb10">
									<el-col :span="4" style="text-align: right;">
										<span>电子邮箱</span>
									</el-col>
									<el-col :span="18">
										<el-input placeholder="请填写有效的电子邮箱">

										</el-input>
									</el-col>
								</el-row>
							</div>
						</el-row>
					</div>
				</div>
				<div class="fill-in-box">
					<div>
						<el-row type="flex" justify="space-between" class="fill-title">
							<div>
								<span class="iconfont icon-chuqianbingpeisong" style="font-size:20px;margin-right:10px;"></span>
								<span>配送信息</span>
							</div>
						</el-row>
					</div>
					<div class="fill-box">
						<el-radio-group v-model="radio" class="mt20 mb20">
							<el-radio :label="3">无需配送</el-radio>
							<el-radio :label="6">上门自取</el-radio>
							<el-radio :label="9">送票上门</el-radio>
						</el-radio-group>
						<div class="ft14 gray6" v-if="radio==3">无需配送,直接发送行程单到邮箱</div>
						<div class="pick-up-ticket" v-if="radio==6">
							<span class="mr20">取票地址：广东省广州市白云区黄石路黄石花园1街2号。</span>
							<span class="">电话：020-28802399</span>
						</div>
						<div class="pick-up-ticket" v-if="radio==9">
							<el-row>
								<el-col :span="10" v-for="(item,index) in [1,2,3,4]" :key="index" class="mr20 mb20">
									<div class="address-inner">
										<span class="iconfont del icon-del icon-chalv-guanbi icon-guanbi2"></span>
										<p class="ft14">张三<span class="ml20">15589356596</span></p>
										<p class="address">广东省广州市白云区黄石东路黄园一街2号</p>
									</div>
								</el-col>
							</el-row>
							<span class="add-address-btn" @click="openAdress(2)">新增地址</span>
						</div>

					</div>
				</div>
				<div class="fill-in-box">
					<div>
						<el-row type="flex" justify="space-between" class="fill-title">
							<div>
								<span class="iconfont icon-chalv-bianji" style="font-size:20px;margin-right:10px;"></span>
								<span>备注</span>
							</div>
						</el-row>
					</div>
					<div class="fill-box mt20">
						<el-input type="textarea" :rows="6" placeholder="请输入您的需求" v-model="textarea">
						</el-input>
					</div>
				</div>
				<el-row type="flex" justify="center" align="middle" class="mt20 mb20">

					<el-button type="danger">提交按钮</el-button>

				</el-row>
				<el-row type="flex" justify="center" align="middle" class="mt20 mb20">
					<div class="mt10">
						<el-checkbox v-model="checked">已阅读 </el-checkbox>
						<span class="ft14 limits-color">禁止带上航空器锂电池运输规定信息提示</span>
						<span class="ft14 limits-color">、</span>
						<span class="ft14 limits-color">南航国内运输条件</span>
						<span class="ft14 limits-color">、</span>
						<span class="ft14 limits-color">机票预定协议</span>
						<span class="ft14 limits-color">、</span>
						<span class="ft14 limits-color">防疫期间旅客乘机提醒</span>
						<span class="ft14 limits-color">、</span>
						<span class="ft14 limits-color"  @click="bindToRule('flightRule')">中华人民共和国民用航空法</span>
						<span class="ft14 limits-color">、</span>
						<span class="ft14 limits-color"  @click="bindToRule('safe')">中华人民共和国民用航空安全保卫条例</span>
						<span class="ft14 limits-color">、</span>
						<span class="ft14 limits-color"  @click="bindToRule('danger')">民用航空危险品运输管理规定</span>
						<span class="ft14 limits-color">、</span>
						<span class="ft14 limits-color"  @click="bindToRule('transport')">锂电池航空运输规定</span>
						<span class="ft14 limits-color">、</span>
						<span class="ft14 limits-color"  @click="bindToRule('service')">公共航空运输旅客服务管理规定</span>
					</div>
				</el-row>
			</el-col>

			<el-col :span="8">
				<div class="right-confirm-box">
					<div class="flight-details-box">
						<div class="flight-details-list">
							<el-row type="flex" justify="space-between" class="ft16 flight-details-tit">
								<div>
									<div><span>回程：广州-悉尼</span></div>
									<div class="ft12 gray9"><span>2017.06.22</span><span>周二</span></div>
								</div>
								<el-row type="flex">
									<span class="ft12 gray6 mr20 mt5">余2座</span>
									<span class="refresh-btn">[重新选择]</span>
								</el-row>
							</el-row>
							<div class="flight-details-content pb10">
								<div class="flight-detail ft12">
									<div class="titbox">
										<img src="http://airlineico.b0.upaiyun.com/MU.png!16x16" />
										<span>中国国际航空</span>
										<span class="ml20">CA1316 772</span>
										<span class="fr">经济舱</span>
									</div>
									<el-row type="flex" :gutter="24" align="middle">
										<el-col :span="5">
											<div class="timebox">
												<div>10：05</div>
												<div>10：05</div>
											</div>
										</el-col>
										<el-col :span="6">
											<div class="datebox">
												<div>2017.08.11</div>
												<div>2017.09.11</div>
											</div>
										</el-col>
										<el-col :span="8">
											<div class="airportbox">
												<span class="clearfix"><em class="fl">广州白云国际机场<i class="terminal">T1</i></em></span>
												<span class="clearfix"><em class="fl">北京国际机场<i class="terminal">T1</i></em></span>
											</div>
										</el-col>
										<el-col :span="6">
											<div class="datebox">
												<el-dropdown>
													<span class="change-back">退改签说明</span>
													<el-dropdown-menu slot="dropdown" style="width:200px">
														<el-dropdown-item>黄金糕</el-dropdown-item>
													</el-dropdown-menu>
												</el-dropdown>
											</div>
											<div class="datebox">
												<el-dropdown>
													<span class="change-back">行李托运</span>
													<el-dropdown-menu slot="dropdown" style="width:200px">
														<el-dropdown-item>黄金糕</el-dropdown-item>
													</el-dropdown-menu>
												</el-dropdown>
											</div>
										</el-col>
									</el-row>
								</div>
							</div>
						</div>
						<div class="flight-details-list">
							<el-row type="flex" justify="space-between" class="ft16 flight-details-tit">
								<div>
									<div><span>回程：广州-悉尼</span></div>
									<div class="ft12 gray9"><span>2017.06.22</span><span>周二</span></div>
								</div>
								<el-row type="flex">
									<span class="ft12 gray6 mr20 mt5">余2座</span>
									<span class="refresh-btn">[重新选择]</span>
								</el-row>
							</el-row>
							<div class="flight-details-content pb10">
								<div class="flight-detail ft12">
									<div class="titbox">
										<img src="http://airlineico.b0.upaiyun.com/MU.png!16x16" />
										<span>中国国际航空</span>
										<span class="ml20">CA1316 772</span>
										<span class="fr">经济舱</span>
									</div>
									<el-row type="flex" :gutter="24" align="middle">
										<el-col :span="5">
											<div class="timebox">
												<div>10：05</div>
												<div>10：05</div>
											</div>
										</el-col>
										<el-col :span="6">
											<div class="datebox">
												<div>2017.08.11</div>
												<div>2017.09.11</div>
											</div>
										</el-col>
										<el-col :span="8">
											<div class="airportbox">
												<span class="clearfix"><em class="fl">广州白云国际机场<i class="terminal">T1</i></em></span>
												<span class="clearfix"><em class="fl">北京国际机场<i class="terminal">T1</i></em></span>
											</div>
										</el-col>
										<el-col :span="6">
											<div class="datebox">
												<el-dropdown>
													<span class="change-back">退改签说明</span>
													<el-dropdown-menu slot="dropdown" style="width:200px">
														<el-dropdown-item>黄金糕</el-dropdown-item>
													</el-dropdown-menu>
												</el-dropdown>
											</div>
											<div class="datebox">
												<el-dropdown>
													<span class="change-back">行李托运</span>
													<el-dropdown-menu slot="dropdown" style="width:200px">
														<el-dropdown-item>黄金糕</el-dropdown-item>
													</el-dropdown-menu>
												</el-dropdown>
											</div>
										</el-col>
									</el-row>
								</div>
							</div>
						</div>
						<div class="flight-details-list" style="padding:10px">
							<el-row type="flex" justify="end">
								<el-dropdown>
									<span class="change-back" style="color:#333">行程单</span>
									<el-dropdown-menu slot="dropdown" style="width:200px;">
										<el-dropdown-item>黄金糕</el-dropdown-item>
									</el-dropdown-menu>
								</el-dropdown>
							</el-row>
						</div>
					</div>
					<div :class=" (gotop==true?'mt20 flight-price-box fixed-top':'mt20 flight-price-box')">
						<el-row type="flex" justify="space-between" class="hd">
							<div>价格明细</div>
						</el-row>

						<div class="pricebox pb20">
							<div class="item-box">
								<div class="item-list clearfix fb">
									<div class="item-before tr">成人总价</div>
									<div class="item-line"></div>
									<div class="item-after"><em class="ft12">¥</em>12020</div>
								</div>
								<div class="item-list clearfix">
									<div class="item-before tr">成人票价</div>
									<div class="item-line"></div>
									<div class="item-after"><em class="ft12">¥</em>900<em class="ft12">X1</em></div>
								</div>
								<div class="item-list clearfix">
									<div class="item-before tr">参考税费</div>
									<div class="item-line"></div>
									<div class="item-after"><em class="ft12">¥</em>900<em class="ft12">X1</em></div>
								</div>
								<div class="item-list clearfix">
									<div class="item-before tr">航空意外险</div>
									<div class="item-line"></div>
									<div class="item-after"><em class="ft12">¥</em>900<em class="ft12">X1</em></div>
								</div>
							</div>
							<div class="item-box">
								<div class="item-list clearfix fb">
									<div class="item-before tr">儿童总价</div>
									<div class="item-line"></div>
									<div class="item-after"><em class="ft12">¥</em>12020</div>
								</div>
								<div class="item-list clearfix">
									<div class="item-before tr">儿童票价</div>
									<div class="item-line"></div>
									<div class="item-after"><em class="ft12">¥</em>900<em class="ft12">X1</em></div>
								</div>
								<div class="item-list clearfix">
									<div class="item-before tr">参考税费</div>
									<div class="item-line"></div>
									<div class="item-after"><em class="ft12">¥</em>900<em class="ft12">X1</em></div>
								</div>
								<div class="item-list clearfix">
									<div class="item-before tr">航空意外险</div>
									<div class="item-line"></div>
									<div class="item-after"><em class="ft12">¥</em>900<em class="ft12">X1</em></div>
								</div>
							</div>
							<div class="item-box">
								<div class="item-list clearfix fb">
									<div class="item-before tr">现金劵</div>
									<div class="item-line"></div>
									<div class="item-after">-<em class="ft12">¥</em>50</div>
								</div>
							</div>
						</div>
						<el-row type="flex" justify="space-between" class="hd">
							<div>应付总额</div>
							<div style="font-weight:700;font-size:35px;"> 
								<span class="price"><em class="ft12">¥</em>12020</span>
							</div>
						</el-row>
					</div>
				</div>
			</el-col>
		</el-row>
		<SelectPerson :showOftenPassenger="showOftenPassenger" @hideSelectPassengerBox="hideSelectPassengerBox" />
		<desModal :titleRight="openAddress==2" title="新增地址" :footerNone="true" :delShow="true" class="person-modal"
		 :showClose="false" widthNum="600px" @clickDelAction="clickDel">
			<el-row type="flex" justify="space-between" class="pd10" align="middle">
				<el-col :span="4" style="text-align: right;margin-right:10px;">
					<span>选择地区</span>
				</el-col>
				<el-col :span="19">
					<el-row type="flex" justify="space-between">
						<el-col :span="7">
							<el-select v-model="value" placeholder="请选择" style="width:100%;">
								<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
								</el-option>
							</el-select>
						</el-col>
						<el-col :span="7">
							<el-select v-model="value" placeholder="请选择" style="width:100%;">
								<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
								</el-option>
							</el-select>
						</el-col>
						<el-col :span="7">
							<el-select v-model="value" placeholder="请选择" style="width:100%;">
								<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
								</el-option>
							</el-select>
						</el-col>

					</el-row>
				</el-col>
			</el-row>
			<el-row type="flex" justify="space-between" class="pd10" align="middle">
				<el-col :span="4" style="text-align: right;margin-right:10px;">
					<span>详细地址</span>
				</el-col>
				<el-col :span="19">
					<el-row type="flex" justify="space-between">
						<el-col :span="24">
							<el-input placeholder="请输入详细地址"></el-input>
						</el-col>
					</el-row>
				</el-col>
			</el-row>
			<el-row type="flex" justify="space-between" class="pd10" align="middle">
				<el-col :span="4" style="text-align: right;margin-right:10px;">
					<span>收件人姓名</span>
				</el-col>
				<el-col :span="19">
					<el-row type="flex" justify="space-between">
						<el-col :span="24">
							<el-input placeholder="请输入收件人姓名"></el-input>
						</el-col>
					</el-row>
				</el-col>
			</el-row>
			<el-row type="flex" justify="space-between" class="pd10" align="middle">
				<el-col :span="4" style="text-align: right;margin-right:10px;">
					<span>收件人手机</span>
				</el-col>
				<el-col :span="19">
					<el-row type="flex" justify="space-between">
						<el-col :span="24">
							<el-input placeholder="收件人手机"></el-input>
						</el-col>
					</el-row>
				</el-col>
			</el-row>
		</desModal>
	</div>
</template>

<script>
	import SelectPerson from './components/SelectPerson.vue';
	import desModal from '../../components/desModal.vue';
	var that;
	export default {

		components: {
			SelectPerson,
			desModal
		},
         mounted() {
			// 此处true需要加上，不加滚动事件可能绑定不成功
			window.addEventListener('scroll', this.handleScroll, true);
			that=this;
		},
		data() {
			return {
				checkList: ['选中且禁用', '复选框 A'],
				showAll: false,
				options: [{
					value: '选项1',
					label: '护照'
				}],
				value: '',
				radio: 3,
				textarea: '',
				showOftenPassenger: false,
				openAddress: false,
				show2: true,
				count: '',
				timer: null,
				gotop:false,
				
			};
		},
		methods: {
			bindToRule(i){
				that.$router.push({
					path: '/Flight/xieyi',
					query: {
						rule:i,
					}
				});
			},
			handleScroll() {
				let scrolltop = document.documentElement.scrollTop || document.body.scrollTop;
			
				scrolltop > 900 ? (this.gotop = true) : (this.gotop = false);
				console.log(scrolltop,this.gotop);
			},
			getCode() {
				const TIME_COUNT = 60;
				if (!this.timer) {
					this.count = TIME_COUNT;
					this.show2 = false;
					this.timer = setInterval(() => {
						if (this.count > 0 && this.count <= TIME_COUNT) {
							this.count--;
						} else {
							this.show2 = true;
							clearInterval(this.timer);
							this.timer = null;
						}
					}, 1000)
				}
			},
			hide() {
				this.showAll = false
			},
			show() {
				this.showAll = true
			},
			openPerson(e) {

				this.showOftenPassenger = true
			},
			hideSelectPassengerBox() {
				this.showOftenPassenger = false
			},
			openAdress(e) {
				this.openAddress = e
			},
			clickDel() {
				this.openAddress = ""
			}

		}
	};
</script>

<style lang="scss">
	@import '../../style/Flight/flight.scss';
</style>
