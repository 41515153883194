<template>
	<div>
		<desModal :titleRight="showOftenPassenger" title="我的常旅客" :footerNone="true" :delShow="true" class="person-modal"
			:showClose="false" widthNum="600px" @clickDelAction="hideSelectPassengerBox" >
			<div>
				<span class="add-passenger-btn" @click="openAdd(1)">新增常旅客</span>
			</div>
			<el-row type="flex" justify="space-between" class="person-list">
				<el-row type="flex" :gutter="24" class="ft14">
					<el-col :span="6">
						<span class="iconfont icon-checkbox"></span>
						<span class="iconfont icon-duoxuanxuanzhong"></span>
					</el-col>
					<el-col :span="18">
						<div class="check-content">
							<p>王欢 WANG/HUANG</p>
							<p class="gray6">护照：423456</p>
						</div>
					</el-col>
				</el-row>
				<span class="iconfont icon-chalv-bianji" style="font-size:20px;"></span>
			</el-row>

		</desModal>
		<desModal :titleRight="openModel==1" title="新增常旅客" :footerNone="true" :delShow="true" class="person-modal"
			:showClose="false" widthNum="600px"  @clickDelAction="delDetail">
			<el-row type="flex" justify="space-between" class="pd10" align="middle">
				<el-col :span="4" style="text-align: right;margin-right:10px;">
					<span>姓名</span>
				</el-col>
				<el-col :span="19">
					<el-row type="flex" justify="space-between">
						<el-col :span="11">
							<el-input placeholder="姓(拼音)   如:ZHANG"></el-input>
						</el-col>
						<el-col :span="11">
							<el-input placeholder="名(拼音)   如:SAN"></el-input>
						</el-col>
					</el-row>
				</el-col>
			</el-row>
			<el-row type="flex" justify="space-between" class="pd10" align="middle">
				<el-col :span="4" style="text-align: right;margin-right:10px;">
					<span>证件类型</span>
				</el-col>
				<el-col :span="19">
					<el-row type="flex" justify="space-between">
						<el-col :span="24">
							<el-select v-model="value" placeholder="请选择" style="width:100%;">
								<el-option v-for="item in options" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</el-col>

					</el-row>
				</el-col>
			</el-row>
			<el-row type="flex" justify="space-between" class="pd10" align="middle">
				<el-col :span="4" style="text-align: right;margin-right:10px;">
					<span>证件号码</span>
				</el-col>
				<el-col :span="19">
					<el-row type="flex" justify="space-between">
						<el-col :span="24">
							<el-input placeholder="证件号码"></el-input>
						</el-col>
					</el-row>
				</el-col>
			</el-row>
			<el-row type="flex" justify="space-between" class="pd10" align="middle">
				<el-col :span="4" style="text-align: right;margin-right:10px;">
					<span>证件有效期</span>
				</el-col>
				<el-col :span="19">
					<el-row type="flex" justify="space-between">
						<el-col :span="24">
							<el-date-picker v-model="value2" type="date" placeholder="YYYY-MM-DD" style="width:100%;">
							</el-date-picker>
						</el-col>
					</el-row>
				</el-col>
			</el-row>
			<el-row type="flex" justify="space-between" class="pd10" align="middle">
				<el-col :span="4" style="text-align: right;margin-right:10px;">
					<span>出生日期</span>
				</el-col>
				<el-col :span="19">
					<el-row type="flex" justify="space-between">
						<el-col :span="24">
							<el-date-picker v-model="value1" type="date" placeholder="YYYY-MM-DD" style="width:100%;">
							</el-date-picker>
						</el-col>
					</el-row>
				</el-col>
			</el-row>
			<el-row type="flex" justify="space-between" class="pd10" align="middle">
				<el-col :span="4" style="text-align: right;margin-right:10px;">
					<span>国籍</span>
				</el-col>
				<el-col :span="19">
					<el-row type="flex" justify="space-between">
						<el-col :span="24">
							<el-input placeholder="国籍"></el-input>
						</el-col>
						
					</el-row>
				</el-col>
			</el-row>
			<el-row type="flex" justify="space-between" class="pd10" align="middle">
				<el-col :span="4" style="text-align: right;margin-right:10px;">
					<span>性别</span>
				</el-col>
				<el-col :span="19">
					<el-row type="flex" justify="space-between">
						<el-col :span="24">
							  <el-radio v-model="radio" label="1">男</el-radio>
							  <el-radio v-model="radio" label="2">女</el-radio>
						</el-col>
						
					</el-row>
				</el-col>
			</el-row>
		</desModal>
	</div>
</template>

<script>
	import desModal from '../../../components/desModal.vue';
	export default {
		name: 'SelectPerson',
		components: {
			desModal
		},
		props: ['showOftenPassenger', 'IsChinaCity'],
		data() {
			return {
				options: [{
					value: '选项1',
					label: '护照'
				}],
				value: '',
				value1:'',
				value2:'',
				radio:'',
				openModel:""
			};
		},
		methods: {
			openAdd(e){
				this.openModel=1
			},
			delDetail(){
				this.openModel=""
			},
			hideSelectPassengerBox() {
				// this.showWorkerValue = false;
				this.$emit('hideSelectPassengerBox');
			},


		}
	};
</script>

<style lang="scss">
	@import '../../../style/Flight/flight.scss';
</style>
